import React from 'react'
import { Route, Routes } from 'react-router-dom'; // Import from react-router-dom
import Hero from './components/Hero'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import AboutUs from './components/AboutUs'
import ContactUs from './components/ContactUs';

const App = () => {
  return (

    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Hero />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
      </Routes>
      <Footer />
    </>

  )
}

export default App