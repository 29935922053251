// Modal.js
"use client"
import React from 'react';
import { motion } from 'framer-motion';
import ReactPlayer from 'react-player';

const Modal = ({ closeModal, videoUrl }) => {
  const isMobile = window.innerWidth <= 768;
  const width = isMobile ? '100%' : '80%'; // Adjust as needed

  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-60"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className=" p-4 rounded-lg shadow-lg" style={{ width: width, maxWidth: '500px' }}>
        <button
          onClick={closeModal}
          className="absolute top-4 right-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Close
        </button>
        <div className="relative" style={{ paddingTop: '56.25%' }}>
          <ReactPlayer
            url={videoUrl}
            className="absolute inset-0 w-full h-full rounded-lg shadow-lg"
            controls={true}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Modal;
