import phoneHero from "../assets/Signup.png";
import emailjs from '@emailjs/browser';
import React, { useRef } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";


const SignUp = () => {

  const form = useRef();


  const handleSignUp = (e) => {
    e.preventDefault();
    console.log('click')
    emailjs.sendForm('service_zwoz4ju', 'template_jwqep67', form.current, 'Hv5Qdu_UDJfL4qjNm')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset()
  };
  return (
    <section className="bg-gray-900">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-36 lg:grid-cols-12">
        <div className="mr-auto place-self-center lg:col-span-7">
          <h1 className="max-w-2xl mb-4 font-extrabold tracking-tight leading-relaxed text-6xl text-white ">Pre-Launch Sign Up</h1>
          <p className="max-w-2xl mb-6 font-light text-white lg:mb-8 md:text-lg lg:text-2xl text-2xl ">Hurry! Sign up today, to be the first to download the Memory Jar app!</p>
          <form onSubmit={handleSignUp}>
            <input
              type="email"
              name="signupemail"
              className="w-full px-3 py-2 placeholder-gray-400 border rounded-md shadow-sm focus:ring focus:ring-primary-300 focus:ring-opacity-50"
              placeholder="Email"
              required
            />
            <div className='mt-8'>
              <button type="submit" className="inline-flex items-center justify-center py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 focus:ring-primary-900">
                Sign Up
                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
              </button>
            </div>
          </form>
        </div>
        <div className="lg:col-span-5">
          <div className="flex flex-col items-center lg:items-start lg:mt-8 mt-12">
            <LazyLoadImage
              src={phoneHero}
              alt="phoneHero"
              width={1200}
              height={800}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SignUp