import sectionHero from '../assets/section.png'
import { LazyLoadImage } from "react-lazy-load-image-component";

const Section = () => {
  return (

    <section className="bg-gray-900 mt-24">
      <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-2 lg:grid-cols-12 mt-10">
        <div className="lg:col-span-6">
          <div className="flex flex-col items-center lg:items-start lg:mt-5 mt-12">
            <LazyLoadImage src={sectionHero}
              width={1000} height={20}
              alt="phoneHero"
            />
          </div>
        </div>
        <div className="place-self-center lg:col-span-6">
          <h1 className="max-w-2xl mb-4 text-6xl font-extrabold text-white ">A Family Platform to Grow</h1>
          <p className="max-w-2xl mb-6 font-light text-2xl text-white lg:mb-8 md:text-lg lg:text-2xl">In today’s world, families demand trust and security for their content. Simple and easy to use, we’ve created the ultimate application for your family to plan, share, and connect with each other.</p>
        </div>
      </div>

    </section>


  )
}

export default Section