
import phoneHero from "../assets/phoneHero2.png";
import Modal from "./Modal";
import { motion } from "framer-motion";
import {  useState } from "react";
import Section from "./Section";
import SignUp from "./SignUp";
import ParallaxEffect from "./ParallaxEffect";

const Hero = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  console.log('created by: www.nadernasr.ca \n Email: hello@nadernasr.ca');
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  const textVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 10,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 80, // Adjust stiffness as needed
        damping: 10,  // Adjust damping as needed
        delay: 0.5,
      },
    },
  };

  const subTextVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 10,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 80, // Adjust stiffness as needed
        damping: 10,  // Adjust damping as needed
        delay: 0.5,
      },
    },
  };

  return (
    <div className="bg-gray-900">
      <section className="bg-gradient-to-r from-sky-500 from-30% to-emerald-500 to-90% rounded-br-[380px] h-screen lg:pt-48">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-2 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <motion.div
              layout
              initial="hidden"
              animate="visible"
              // exit="hidden"
              variants={textVariants}
            >
              <h1 className="max-w-2xl mb-4 text-3xl font-extrabold md:text-5xl xl:text-6xl text-white">
                Revolutionizing How Families Stay Connected. Forever.
              </h1>
            </motion.div>
            <motion.div
              initial="hidden"
              animate="visible"
              // exit="hidden"
              variants={subTextVariants}
            >
              <p className="max-w-2xl mb-6 font-light lg:mb-8 md:text-lg lg:text-2xl text-2xl text-white">
                Memory Jar is a new and unique concept in the secure & private
                technology space. We give the power back to users and their
                families on their own secure platform.
              </p>
              <div
                onClick={openModal}
                className="inline-flex items-center justify-center py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 focus:ring-primary-900 bg-transparent hover:bg-blue-500  hover:text-white px-4 border border-blue-500 hover:border-transparent cursor-pointer"
              >
                Watch Video
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
            </motion.div>
          </div>
          <div className="lg:col-span-5">
            <div className="flex flex-col items-center lg:items-start">
              <motion.div
                layout
                initial={{ x: 500, opacity: 0 }} // Initial position (bottom)
                animate={{
                  x: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                    damping: 15,
                    delay: 0.8,
                  },
                }} // Animation properties
              >
                <img
                  prefetch
                  src={phoneHero}
                  alt="phoneHero"
                  height={600}
                />
              </motion.div>
            </div>
          </div>
        </div>
      </section>
      <Section/>
      <ParallaxEffect/>
      <SignUp/>

      {isModalOpen && (
        <Modal
          closeModal={closeModal}
          videoUrl="https://youtu.be/KiKJxKCGfow"
        />
      )}
    </div>
  );
};

export default Hero;

