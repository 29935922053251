import React from 'react'
import { Parallax } from 'react-parallax';
import image from '../assets/background.png'


const ParallaxEffect = () => {
  return (
   <div className='m-auto flex flex-col h-auto'>
     <Parallax className='lg:h-60 md:h-60 h-0 sm:w-screen' bgImage={image} bgImageAlt="the cat" strength={300}/>
   </div>
  )
}

export default ParallaxEffect