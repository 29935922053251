import footerLogo from '../assets/footerLogo.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white flex flex-col justify-center items-center h-1/2 pt-12">
      <div className="container mx-auto flex flex-wrap sm:justify-center">
        {/* Column 1 */}
        <div className="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0 px-4">
          <LazyLoadImage src={footerLogo} className="mx-auto" alt="logo" />
        </div>

        {/* Column 2 */}
        <div className="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0 px-4 lg:mt-10 text-center">
          <ul>
            <li><a href="https://www.linkedin.com/company/memoryjar/">LinkedIn</a></li>
            <li><a href="https://www.instagram.com/memory_jar/">Instagram</a></li>
            <li><a href="https://www.youtube.com/channel/UCcEPghbjWgQqfUQ4jEhqL5Q">Youtube</a></li>
          </ul>
        </div>

        {/* Column 3 */}
        <div className="w-full md:w-1/2 lg:w-1/3 mb-4 md:mb-0 px-4 lg:mt-10 text-center">
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/aboutus">About</a></li>
            <li><a href="/contact">Contact Us</a></li>
          </ul>
        </div>
      </div>
      {/* "All Rights Reserved" text */}
      <p className="text-center mt-4 mb-4">&copy; {new Date().getFullYear()} Memory Jar. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
