import React from 'react'

const AboutUs = () => {
  return (
    <>
      <section className="bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white">About Us</h2>
            <p className="mb-8 font-light text-gray-400 lg:mb-16 sm:text-xl"> We are a passionate team dedicated to making a difference in the world of technology and family connections.</p>
          </div>
          <div className="grid mb-8 lg:mb-12 lg:grid-cols-2">
            {/* Testimonial 1 */}
            <figure className="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
              <blockquote className="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">OUR SENTIMENT</h3>
                <p className="my-4">Not long ago, families would create the purest forms of content in the absence of modern technology. We’d build and safeguard our memories for future generations. Today, we create and post as individuals – feeling more available, yet seemingly less connected to one another. Moreover, it seems nothing exists today that feels fun, trusted, and organized for family content. We created Memory Jar because we see a better way. Explore the past. Capture the present. Send to the future.</p>
              </blockquote>
            </figure>

            {/* Testimonial 2 */}
            <figure className="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 md:p-12 dark:bg-gray-800 dark:border-gray-700">
              <blockquote className="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">WHO WE ARE</h3>
                <p className="my-4">User Experience and Family Utility – We are the best of both worlds</p>
                <p className="my-4">Captivating Features – Rigorously tested for engagement</p>
                <p className='my-4'>
                  Groundbreaking approach – the first of its kind in the family content and genealogy space
                  Safe from data mining, targeting, & tracking – your family is safe with us.</p>
              </blockquote>
            </figure>
          </div>

          {/* Testimonial 3 */}
          <figure className="flex flex-col justify-center items-center p-8 text-center bg-gray-50 border-b border-gray-200 lg:border-b-0 md:p-12 lg:border-r dark:bg-gray-800 dark:border-gray-700">
            <blockquote className="mx-auto mb-8 max-w-2xl text-gray-500 dark:text-gray-400">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">OUR MISSION</h3>
              <p className="my-4">It is our mission to operate as a net benefit to society on a global scale. Closer families create happier individuals, which makes for a better world.</p>
            </blockquote>
          </figure>
        </div>
      </section>
    </>
  )
}

export default AboutUs